import { Mesh, MeshPhongMaterial, Color } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { workerColor1, workerColor2, workerScale } from './config';


const workerModelMap = {
    'F': './models/Worker_F1_final.stl',
    'M': './models/Worker_M1_final.stl',
}

export const workerColorMap = [
    workerColor1,
    workerColor2,
]

export const getWorker = (sex) => () => {
    const workerMat = new MeshPhongMaterial();
    workerMat.color = new Color(0xFFFFFF);

    return new Promise((resolve, reject) => {
        const loader = new STLLoader();

        const onError = (err) => { reject('Cannot load worker model: ' + err); };

        loader.load(workerModelMap[sex], function (geometry) {

            const worker = new Mesh(geometry, workerMat);

            geometry.center();

            worker.scale.set(workerScale, workerScale, workerScale);
            worker.rotateX(-Math.PI / 2);

            worker.material.transparent = true;
            worker.material.opacity = 0.5;


            worker.receiveShadow = true;
            worker.position.y = 1.1

            resolve(worker);
        }, () => { }, onError);
    });
}

export const getFemaleWorker = getWorker('F');
export const getMaleWorker = getWorker('M');