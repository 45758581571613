



const groundColor = 0x2f9e4d;
const floorColor = 0x806043;
const lightColor = 0xffffff;
const skyColor = 0x3b8bd1;
const houseColor = 0xffcfcf;
const cupolaColor = 0x3b8bff;
const workerColor1 = 0xCD7F32;
const workerColor2 = 0x3b8bff;
const houseScale = 0.096;
const workerScale = 0.08;
const boardScale = 0.15;


export { groundColor, floorColor, lightColor, skyColor, houseColor, houseScale, boardScale, cupolaColor, workerColor1, workerColor2, workerScale }