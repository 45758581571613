import { Mesh, MeshPhongMaterial, Color } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { cupolaColor, houseColor, houseScale } from './config';

const houseModelMap = {
    '1': './models/Lvl1_v1.stl',
    '2': './models/Lvl2_V1.stl',
    '3': './models/Lvl3_b3.stl',
}

export const getHouse = (level = 1) => () => {

    const houseMat = new MeshPhongMaterial();
    houseMat.color = new Color(houseColor);

    return new Promise((resolve, reject) => {
        let house;

        const loader = new STLLoader();


        const onError = (err) => { reject('Cannot load lv1 house model: ' + err); };

        loader.load(houseModelMap[level.toString()], function (geometry) {
            house = new Mesh(geometry, houseMat);

            house.scale.set(houseScale, houseScale, houseScale);

            house.castShadow = false;
            house.receiveShadow = false;

            house.material.transparent = true;
            house.material.opacity = 0.0;

            house.position.y = 100;

            return resolve(house);
        }, () => { }, onError);
    });
}



export const getLv1House = getHouse(1);
export const getLv2House = getHouse(2);
export const getLv3House = getHouse(3);


export const getCupola = () => {
    const cupolaMat = new MeshPhongMaterial();
    cupolaMat.color = new Color(cupolaColor);

    return new Promise((resolve, reject) => {
        let cupola;

        const loader = new STLLoader();

        const onError = (err) => { reject('Cannot load cupola model: ' + err); };

        loader.load('./models/Dome_b.stl', function (geometry) {
            cupola = new Mesh(geometry, cupolaMat);

            cupola.scale.set(houseScale, houseScale, houseScale);

            cupola.castShadow = false;
            cupola.receiveShadow = false;

            cupola.material.transparent = true;
            cupola.material.opacity = 0.0;

            return resolve(cupola);
        }, () => { }, onError);
    });
}

