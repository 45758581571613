
import { Mesh, MeshPhongMaterial, Color } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { boardScale, groundColor } from './config';

// Materials
const material = new MeshPhongMaterial();
material.color = new Color(groundColor);

export const getBoard = () => {
    return new Promise((resolve, reject) => {
        const loader = new STLLoader();

        const onError = (err) => { reject('Cannot load board model: ' + err); };


        loader.load('./models/Board.stl', function (geometry) {

            const board = new Mesh(geometry, material);

            geometry.center();

            board.scale.set(boardScale, boardScale, boardScale);
            board.rotateX(-Math.PI / 2);

            board.castShadow = true;
            board.receiveShadow = true;
            resolve(board);
        }, () => { }, onError);
    });
};