import * as dat from 'dat.gui';
import { constant, random, times, range } from 'lodash';
import panic from 'panic-overlay';

import * as THREE from 'three';
import {
  BufferGeometry,
  Intersection,
  Mesh,
  MeshPhongMaterial,
  Object3D,
  Vector3,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { getBoard } from './board';
import {
  boardScale,
  cupolaColor,
  floorColor,
  groundColor,
  houseColor,
  lightColor,
  skyColor,
} from './config';
import { getCupola, getLv1House, getLv2House, getLv3House } from './house';
import './style.css';
import { getFemaleWorker, getMaleWorker, workerColorMap } from './worker';

const MAX_PLAYERS = 3;
const PLAYERS = 2;

let placed = 0;

let rendered = false;
const placeholders = [];
let selectedWorkerToMove = null;
let isNextWorkerMoveValid = false;

let lastSelectedIndex = null;

const placeHolderColors = [houseColor, houseColor, houseColor, cupolaColor];

const isEven = (n: number) => n % 2 === 0;
interface WorkerPlaceHolders {
  M: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
  F: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
}

const workerPlaceholders: Array<WorkerPlaceHolders> = range(0, PLAYERS).map(
  () => ({} as WorkerPlaceHolders)
);

// type WorkerType = 'M' | 'F';
const workerTypes: string[] = ['M', 'F'];

// Debug
const gui = new dat.GUI();

// Canvas
const canvas: HTMLCanvasElement = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(skyColor);

var groundMaterial = new THREE.MeshPhongMaterial({
  color: new THREE.Color(floorColor),
});

// Mesh
var mesh = new THREE.Mesh(
  new THREE.PlaneGeometry(10000, 10000),
  groundMaterial
);

mesh.position.y = -3;
mesh.rotation.x = -Math.PI / 2;
mesh.receiveShadow = true;

scene.add(mesh);

const group = new THREE.Group();

let board: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
getBoard().then((loadedBoard) => {
  board = loadedBoard;
  group.add(board);
});

let lv1House: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
getLv1House().then((loadedLv1House) => {
  lv1House = loadedLv1House;
  group.add(lv1House);
  placeholders[0] = lv1House;
});

let lv2House: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
getLv2House().then((loadedLv2House) => {
  lv2House = loadedLv2House;
  group.add(lv2House);
  placeholders[1] = lv2House;
});

let lv3House: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
getLv3House().then((loadedLv3House) => {
  lv3House = loadedLv3House;
  group.add(lv3House);
  placeholders[2] = lv3House;
});

let cupola: THREE.Mesh<BufferGeometry, MeshPhongMaterial>;
getCupola().then((loadedCupola) => {
  cupola = loadedCupola;
  group.add(cupola);
  placeholders[3] = cupola;
});

getFemaleWorker().then((loadedFemaleWorker) => {
  range(0, PLAYERS).forEach((i) => {
    workerPlaceholders[i].F = loadedFemaleWorker.clone();
    workerPlaceholders[i].F.material = loadedFemaleWorker.material.clone();
    workerPlaceholders[i].F.material.color.setHex(
      workerColorMap[Number(isEven(i))]
    );
    workerPlaceholders[i].F.position.x = -1 * i;
    workerPlaceholders[i].F.position.z = -15;
    group.add(workerPlaceholders[i].F);
  });
});

getMaleWorker().then((loadedMaleWorker) => {
  range(0, PLAYERS).forEach((i) => {
    workerPlaceholders[i].M = loadedMaleWorker.clone();
    workerPlaceholders[i].M.material = loadedMaleWorker.material.clone();
    workerPlaceholders[i].M.material.color.setHex(
      workerColorMap[Number(isEven(i))]
    );
    workerPlaceholders[i].M.position.x = -1 * i;
    workerPlaceholders[i].M.position.z = -10;
    group.add(workerPlaceholders[i].M);
  });
});

scene.add(group);

// Lights

const hemiLight = new THREE.HemisphereLight(skyColor, groundColor, 0.3);
hemiLight.position.set(25, 45, 25);
scene.add(hemiLight);

const ambientLight = new THREE.AmbientLight(lightColor, 0.4);

scene.add(ambientLight);

// const spotLight = new THREE.SpotLight(lightColor, 0.5);

// spotLight.target.position.set(-4, 0, -4);

// spotLight.position.set(25, 25, 25);
// spotLight.shadow.mapSize.width = 1024;
// spotLight.shadow.mapSize.height = 1024;
// spotLight.shadow.camera.near = 0.5;
// spotLight.shadow.camera.far = 100;
// spotLight.shadow.camera.fov = 30;

// spotLight.castShadow = true;
// scene.add(spotLight);
// scene.add(spotLight.target);

// const helper = new THREE.CameraHelper(spotLight.shadow.camera);
// scene.add(helper);

const light = new THREE.DirectionalLight(0xf4e99b, 0.7);
light.castShadow = true;
light.position.set(25, 40, 25);
light.shadow.mapSize.width = 1024;
light.shadow.mapSize.height = 1024;
light.shadow.camera.near = 1;
light.shadow.camera.far = 100;
light.target.position.set(0, 0, 0);
scene.add(light);
scene.add(light.target);

// const helper = new THREE.CameraHelper(light.shadow.camera);
// scene.add(helper);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);

camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 10;
scene.add(camera);

const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);

// Controls
const controls = new OrbitControls(camera, canvas as HTMLCanvasElement);
controls.enableDamping = true;
controls.minPolarAngle = Math.PI / 4;
controls.maxPolarAngle = Math.PI / 2;
controls.maxAzimuthAngle = Math.PI / 4;
controls.minDistance = 0.1;
controls.maxDistance = 30;
controls.mouseButtons = { LEFT: 2, MIDDLE: 1, RIGHT: 0 };
controls.enablePan = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;

/**
 * Animate
 */
function render() {
  document.querySelector('#menu').innerHTML = `Selected worker ${
    selectedWorkerToMove
      ? selectedWorkerToMove === 'M'
        ? 'Male'
        : 'Female'
      : 'none'
  } <br> Player state: ${JSON.stringify(playerState)}`;
  renderer.render(scene, camera);
  rendered = true;
}

interface GridPoint {
  x: [number, number];
  z: [number, number];
  i: number;
  j: number;
}
const gridPoints: Array<GridPoint> = [];

const TILES = 5;

const getScaledSizeFromBoard = (size: number) => size * boardScale;

const TILE_SPACING = getScaledSizeFromBoard(3);
const BOARD_SIZE = getScaledSizeFromBoard(112);
const TILE_SIZE = getScaledSizeFromBoard(20);
const TILE_BORDER = getScaledSizeFromBoard(1);
const HOUSE_SIZE = TILE_SIZE - TILE_BORDER * 2;

const CUPOLA_OFFSETX = getScaledSizeFromBoard(8);
const CUPOLA_OFFSETZ = getScaledSizeFromBoard(5.5);
const HOUSE_LV3_HEIGHT = getScaledSizeFromBoard(10);
const HOUSE_LV1_HEIGHT = getScaledSizeFromBoard(16);
const HOUSE_LV2_HEIGHT = getScaledSizeFromBoard(13);

const HOUSE_HEIGHTS = [
  HOUSE_LV1_HEIGHT + 0.1,
  HOUSE_LV2_HEIGHT,
  HOUSE_LV3_HEIGHT,
];

const WORKER_Y_OFFSET = getScaledSizeFromBoard(6.5);
const WORKER_X_OFFSET = getScaledSizeFromBoard(9.75);
const WORKER_Z_OFFSET = getScaledSizeFromBoard(10);

const offsets = [
  { x: 0, y: 0, z: 0 },
  { x: TILE_BORDER + 0.05, y: HOUSE_LV1_HEIGHT, z: -(0.05 + TILE_BORDER) },
  {
    x: TILE_BORDER + 0.05,
    y: HOUSE_LV1_HEIGHT + HOUSE_LV2_HEIGHT,
    z: -(0.05 + TILE_BORDER),
  },
  {
    x: CUPOLA_OFFSETX + TILE_BORDER,
    y: HOUSE_LV1_HEIGHT + HOUSE_LV2_HEIGHT + HOUSE_LV3_HEIGHT,
    z: -TILE_BORDER - CUPOLA_OFFSETZ,
  },
];

enum Turn {
  Init1 = 0,
  Init2 = 1,
  House1 = 10,
  House2 = 11,
  Move1 = 20,
  Move2 = 21,
  Finish = 30,
}

let turn: Turn = Turn.Init1;

function calculateGridPoints() {
  if (gridPoints.length === 0) {
    for (let i = 0; i < TILES; i++) {
      const x = i * (TILE_SIZE + TILE_SPACING) - BOARD_SIZE / 2;
      for (let j = 0; j < TILES; j++) {
        const z = j * (TILE_SIZE + TILE_SPACING) - BOARD_SIZE / 2;

        gridPoints.push({ x: [x, x + TILE_SIZE], z: [z, z + TILE_SIZE], i, j });
      }
    }
  }
}

let selectedIndex = null;

const houses = range(0, TILES * TILES).map(() => []);

function hideAllPlaceholder() {
  lv1House.material.opacity = 0;
  lv2House.material.opacity = 0;
  lv3House.material.opacity = 0;
  cupola.material.opacity = 0;
}

function hideAllWorkerPlaceholder() {
  range(0, PLAYERS).forEach((i: number) => {
    workerPlaceholders[i].M.material.opacity = 0;
    workerPlaceholders[i].F.material.opacity = 0;
  });
}

function resetWorkerColors() {
  range(0, PLAYERS).forEach((i) => {
    workerPlaceholders[i].F.material.color.setHex(workerColorMap[i]);
    workerPlaceholders[i].M.material.color.setHex(workerColorMap[i]);
  });
}

function placeHouse(index: string | number, level = 0) {
  if (level > 3) return;
  const house = placeholders[level];
  const newHouse = house.clone();
  house.material.opacity = 0;
  newHouse.material = house.material.clone();
  newHouse.material.opacity = 1;
  newHouse.receiveShadow = true;
  newHouse.castShadow = true;
  newHouse.material.transparent = false;

  if (level === 0 && random(0, 10) % 2 === 0) {
    newHouse.rotateY([Math.PI / 2]);
    newHouse.translateZ(HOUSE_SIZE);
  }

  houses[index] = [...houses[index], newHouse];

  scene.add(newHouse);
}

interface WorkerState {
  M: number;
  F: number;
}

type PlayerState = Array<WorkerState>;

const playerState: PlayerState = range(0, PLAYERS).map(() => ({
  M: -1,
  F: -1,
}));

function getWorkerState(playerIndex: number): WorkerState {
  return playerState[playerIndex];
}

function isOwnWorkerOnTile(index: number, playerIndex: number): boolean {
  return (
    getWorkerState(playerIndex).M === index ||
    getWorkerState(playerIndex).F === index
  );
}

function getWorkerTypeOnTile(index: number, playerIndex: number): string {
  const workerState = getWorkerState(playerIndex);
  if (workerState.M === index) return 'M';
  if (workerState.F === index) return 'F';
  return '';
}

function isWorkerOnTile(index: number): boolean {
  return range(0, PLAYERS).some((i: number) => {
    return isOwnWorkerOnTile(index, i);
  });
}

function placeWorker(playerIndex: number, workerType: string) {
  if (!workerTypes.includes(workerType)) return;

  const worker = workerPlaceholders[playerIndex][workerType];
  worker.material.opacity = 1;
  worker.receiveShadow = true;
  worker.castShadow = true;
  worker.material.transparent = false;
}

function saveWorkerState(playerIndex: number, workerType: string, index: any) {
  playerState[playerIndex][workerType] = index;
}

function placePlaceholder(
  index: string | number,
  level = 0,
  offset: { x: any; y: any; z: any },
  isValidMove = true
) {
  if (level > 3) return;
  const house = placeholders[level];
  house.material.opacity = 0.5;
  if (isValidMove) {
    house.material.color.setHex(placeHolderColors[level]);
  } else {
    house.material.color.setHex(0xff6961);
  }
  house.position.x = gridPoints[index].x[0] + TILE_BORDER + offset.x;
  house.position.z =
    gridPoints[index].z[0] + TILE_SIZE - TILE_BORDER + offset.z;
  house.position.y = offset.y;
}

function getClosestIndex(
  gridPoints: GridPoint[],
  intersections: Intersection<Object3D>[]
) {
  const closestIndex = gridPoints.findIndex(
    ({ x: xp, z: zp }) =>
      intersections[0].point.x >= xp[0] &&
      intersections[0].point.x <= xp[1] &&
      intersections[0].point.z >= zp[0] &&
      intersections[0].point.z <= zp[1]
  );

  return closestIndex;
}

function nextTurn() {
  const transitions = {
    [Turn.Init1]: Turn.Init2,
    [Turn.Init2]: Turn.Move1,
    [Turn.House1]: Turn.Move2,
    [Turn.Move1]: Turn.House1,
    [Turn.House2]: Turn.Move1,
    [Turn.Move2]: Turn.House2,
  };

  turn = transitions[turn];
}

/** WIN CONDITIONS */

function hasWonByStepOnThirdLevel(lastStepIndex: number): boolean {
  return houses[lastStepIndex].length === 3;
}

function canStepOnTile(playerIndex: number): boolean {
  const workerState = getWorkerState(playerIndex);

  const workerIndexFemale = workerState.F;
  const workerIndexMale = workerState.M;

  const workerIndexFemale2D = get2DIndexFromFlatIndex(workerIndexFemale);
  const workerIndexMale2D = get2DIndexFromFlatIndex(workerIndexMale);

  const tiles = range(0, TILES * TILES).filter((i) => {
    const index2D = get2DIndexFromFlatIndex(i);

    // neighbour of workerIndexFemale2D
    const isNeighbourOfFemale =
      (index2D.x === workerIndexFemale2D.x - 1 &&
        index2D.y === workerIndexFemale2D.y) ||
      (index2D.x === workerIndexFemale2D.x + 1 &&
        index2D.y === workerIndexFemale2D.y) ||
      (index2D.x === workerIndexFemale2D.x &&
        index2D.y === workerIndexFemale2D.y - 1) ||
      (index2D.x === workerIndexFemale2D.x &&
        index2D.y === workerIndexFemale2D.y + 1);

    // neighbour of workerIndexMale2D
    const isNeighbourOfMale =
      (index2D.x === workerIndexMale2D.x - 1 &&
        index2D.y === workerIndexMale2D.y) ||
      (index2D.x === workerIndexMale2D.x + 1 &&
        index2D.y === workerIndexMale2D.y) ||
      (index2D.x === workerIndexMale2D.x &&
        index2D.y === workerIndexMale2D.y - 1) ||
      (index2D.x === workerIndexMale2D.x &&
        index2D.y === workerIndexMale2D.y + 1);

    if (!isNeighbourOfFemale && !isNeighbourOfMale) {
      return false;
    }

    return ['M', 'F'].some((workerType) => {
      const workerIndex = playerState[playerIndex][workerType];

      const houseCountOnSelectedGrid = houses[i].length;
      const houseCountOnWorkerGrid = houses[workerIndex].length;

      const distance = getWorkerDistanceFromSelectedIndex(i, workerIndex);

      const isNextStepOneLevelAboveOrBelow =
        Math.abs(houseCountOnWorkerGrid - houseCountOnSelectedGrid) <= 1;

      if (!isNextStepOneLevelAboveOrBelow) {
        return false;
      }

      if (isWorkerOnTile(i)) {
        return false;
      }

      if (distance >= 1.5) {
        return false;
      }

      if (houseCountOnSelectedGrid > 3) {
        return false;
      }

      return true;
    });
  });

  return tiles.length > 0;
}

/** GAME LOGIC */
function onClick(event: MouseEvent) {
  if (!rendered) {
    return;
  }

  event.preventDefault();
  calculateGridPoints();

  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const intersections = raycaster.intersectObject(board, true);

  if (intersections[0]) {
    const closestIndex = getClosestIndex(gridPoints, intersections);

    if (selectedIndex === closestIndex) {
      console.log('selectedIndex', selectedIndex, 'closestIndex', closestIndex);
      switch (turn) {
        case Turn.Init1:
        case Turn.Init2: {
          const playerIndex = getPlayerIndexFromTurn(turn);
          if (isWorkerOnTile(closestIndex)) {
            return;
          }

          const workerType = placed < PLAYERS ? 'M' : 'F';

          placeWorker(playerIndex, workerType);
          saveWorkerState(playerIndex, workerType, closestIndex);
          if (placed == PLAYERS * 2 - 1) {
            nextTurn();
          } else {
            const nextInit = {
              [Turn.Init1]: Turn.Init2,
              [Turn.Init2]: Turn.Init1,
            };

            placed++;
            turn = nextInit[turn];
          }
          break;
        }

        case Turn.House1:
        case Turn.House2: {
          if (!isNextWorkerMoveValid) {
            break;
          }
          placeHouse(closestIndex, houses[closestIndex].length);
          nextTurn();

          break;
        }
        case Turn.Move1:
        case Turn.Move2: {
          if (selectedWorkerToMove) {
            if (isNextWorkerMoveValid) {
              const workerType = selectedWorkerToMove;
              const playerIndex = getPlayerIndexFromTurn(turn);
              placeWorker(playerIndex, workerType);
              saveWorkerState(playerIndex, workerType, closestIndex);

              selectedWorkerToMove = null;

              if (hasWonByStepOnThirdLevel(closestIndex)) {
                alert(`Player ${playerIndex} has won!`);
                turn = Turn.Finish;
              }

              nextTurn();
            }
          } else {
            const playerIndex = getPlayerIndexFromTurn(turn);
            const workerState = getWorkerState(playerIndex);
            if (workerState.M === closestIndex) {
              selectedWorkerToMove = 'M';
            } else if (workerState.F === closestIndex) {
              selectedWorkerToMove = 'F';
            }

            console.log('selected worker state', selectedWorkerToMove);
          }
        }
      }

      selectedIndex = null;
    }
  }

  render();
}

function placeWorkerPlaceholder(
  index: string | number,
  playerIndex: number,
  type = 'M',
  isValidMove = true
) {
  if (!workerTypes.includes(type)) {
    throw new Error('type must be M or F');
  }
  const worker: THREE.Mesh<BufferGeometry, MeshPhongMaterial> =
    workerPlaceholders[playerIndex][type];

  if (isValidMove) {
    worker.material.color.setHex(workerColorMap[playerIndex]);
  } else {
    worker.material.color.setHex(0xff6961);
  }
  worker.material.transparent = true;
  worker.material.opacity = 0.8;
  worker.position.x = gridPoints[index].x[0] + WORKER_X_OFFSET;
  worker.position.z = gridPoints[index].z[0] + WORKER_Z_OFFSET;
  worker.position.y = WORKER_Y_OFFSET;
}

function resetWorkerY(worker: { position: { y: number } }) {
  worker.position.y = WORKER_Y_OFFSET;
}

function getPlayerIndexFromTurn(t: Turn) {
  return Number(String(t).split('').slice(-1)[0]);
}

function elevateWorker(
  worker: THREE.Object3D<THREE.Event>,
  houseCount: number
) {
  if (houseCount === 0) return resetWorkerY(worker);
  worker.position.y =
    WORKER_Y_OFFSET +
    HOUSE_HEIGHTS.slice(0, houseCount).reduce((a, b) => a + b, 0) -
    getScaledSizeFromBoard(1);
}

function get2DIndexFromFlatIndex(index: number) {
  const x = index % TILES;
  const y = Math.floor(index / TILES);

  return { x, y };
}

function getFlatIndexFrom2DIndex(x: number, y: number) {
  return x + y * TILES;
}

const getWorkerDistanceFromSelectedIndex = (
  closestIndex: any,
  workerIndex: number
) => {
  const closest2DIndex = get2DIndexFromFlatIndex(closestIndex);
  const worker2DIndex = get2DIndexFromFlatIndex(workerIndex);

  const distance = Math.sqrt(
    (closest2DIndex.x - worker2DIndex.x) ** 2 +
      (closest2DIndex.y - worker2DIndex.y) ** 2
  );

  return distance;
};

function highlightWorker(worker: Mesh<BufferGeometry, MeshPhongMaterial>) {
  worker.material.color.setHex(0x00ff00);
}

function onMouseMove(event: MouseEvent) {
  event.preventDefault();

  if (!rendered) {
    return;
  }

  calculateGridPoints();

  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const intersections = raycaster.intersectObject(board, true);
  if (intersections[0]) {
    const closestIndex = getClosestIndex(gridPoints, intersections);

    if (closestIndex !== -1) {
      switch (turn) {
        case Turn.Init1:
        case Turn.Init2: {
          const playerIndex = getPlayerIndexFromTurn(turn);
          const workerType = placed < PLAYERS ? 'M' : 'F';
          placeWorkerPlaceholder(
            closestIndex,
            playerIndex,
            workerType,
            !isWorkerOnTile(closestIndex)
          );
          render();

          break;
        }
        case Turn.House1:
        case Turn.House2: {
          hideAllPlaceholder();

          const houseCountOnGrid = houses[closestIndex].length;
          const playerIndex = getPlayerIndexFromTurn(turn);

          const workerIndexFemale = playerState[playerIndex].F;
          const workerIndexMale = playerState[playerIndex].M;

          const houseCountOnSelectedGrid = houses[closestIndex].length;

          const distanceFemale = getWorkerDistanceFromSelectedIndex(
            closestIndex,
            workerIndexFemale
          );

          const distanceMale = getWorkerDistanceFromSelectedIndex(
            closestIndex,
            workerIndexMale
          );

          isNextWorkerMoveValid =
            !isWorkerOnTile(closestIndex) &&
            (distanceFemale <= 1.5 || distanceMale <= 1.5) &&
            houseCountOnSelectedGrid < 4;

          placePlaceholder(
            closestIndex,
            houseCountOnGrid,
            offsets[houseCountOnGrid],
            isNextWorkerMoveValid
          );
          render();
          break;
        }
        case Turn.Move1:
        case Turn.Move2: {
          const playerIndex = getPlayerIndexFromTurn(turn);
          resetWorkerColors();

          if (!canStepOnTile(playerIndex)) {
            turn = Turn.Finish;
            alert(`You cannot step on any tile Player ${playerIndex} loose.`);
            break;
          }

          if (selectedWorkerToMove) {
            const workerType = selectedWorkerToMove;
            const workerIndex = playerState[playerIndex][workerType];

            const houseCountOnSelectedGrid = houses[closestIndex].length;
            const houseCountOnWorkerGrid = houses[workerIndex].length;

            const distance = getWorkerDistanceFromSelectedIndex(
              closestIndex,
              workerIndex
            );

            const isNextStepOneLevelAboveOrBelow =
              Math.abs(houseCountOnWorkerGrid - houseCountOnSelectedGrid) <= 1;
            isNextWorkerMoveValid =
              !isWorkerOnTile(closestIndex) &&
              distance <= 1.5 &&
              houseCountOnSelectedGrid < 4 &&
              isNextStepOneLevelAboveOrBelow;

            placeWorkerPlaceholder(
              closestIndex,
              playerIndex,
              workerType,
              isNextWorkerMoveValid
            );
            elevateWorker(
              workerPlaceholders[playerIndex][workerType],
              houseCountOnSelectedGrid
            );
          } else {
            if (isOwnWorkerOnTile(closestIndex, playerIndex)) {
              highlightWorker(
                workerPlaceholders[playerIndex][
                  getWorkerTypeOnTile(closestIndex, playerIndex)
                ]
              );
            }
          }
          render();
          break;
        }
        default:
          break;
      }

      selectedIndex = closestIndex;
    }
  }
}

renderer.domElement.addEventListener('click', onClick);
renderer.domElement.addEventListener('mousemove', onMouseMove);

renderer.domElement.addEventListener('contextmenu', (event) => {
  event.preventDefault();
  if (selectedWorkerToMove === null) {
    return;
  }

  const playerIndex = getPlayerIndexFromTurn(turn);
  const workerIndex = playerState[playerIndex][selectedWorkerToMove];

  placeWorkerPlaceholder(workerIndex, playerIndex, selectedWorkerToMove, true);
  placeWorker(playerIndex, selectedWorkerToMove);
  selectedWorkerToMove = null;

  render();
});

// const controls = new ObjectControls(camera, renderer.domElement, group);
// controls.setDistance(12, 20); // set min - max distance for zoom
// controls.setZoomSpeed(0.4); // set zoom speed
// controls.enableHorizontalRotation();
// controls.enableVerticalRotation();
// controls.setMaxVerticalRotationAngle(Math.PI / 4, Math.PI / 4);
// controls.setMaxHorizontalRotationAngle(Math.PI / 4, Math.PI / 4);
// controls.setRotationSpeed(0.05);

controls.addEventListener('change', render);
document.addEventListener('load', render);
setTimeout(render, 1000);
panic.configure({ projectRoot: '/Users/mac/Projects/games/santorini' });
